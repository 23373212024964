import React from 'react'

import RedBull from '../assets/Red-Bull-logo.png';
import Haven from '../assets/haven.png';
import Darts from '../assets/darts.png';
import Demeter from '../assets/demeter.jpg';

function Sponsor() {
    return (
        <section className="bg-black">
        <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4 ">
          
            <div className="grid grid-cols-4 gap-5 text-gray-500 sm:gap-5 md:grid-cols-4 lg:grid-cols-4 dark:text-gray-400">
                <div className="flex justify-center items-center">
                    <img className="h-16 pl-4 " src={Darts} alt="logo"/>
                </div>
                <div className="flex justify-center items-center">
                    <img className="h-18 pl-4 " src={Haven} alt="logo"/>                                      
                </div>
                <div className="flex justify-center items-center">
                    <img className="h-16 pl-4 " src={RedBull} alt="logo"/>                       
                </div>
    
                <div className="flex justify-center items-center">
                    <img className="h-16 pl-4 " src={Demeter} alt="logo"/>                                                                      
                </div>
                
            </div>
        </div>
    </section>
    )
}

export default Sponsor
