
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = ({data}) => {

  const notify = () => toast.success('Successful operation', {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_au0b7v7', 'template_64za58f', e.target, '7qCF3tqd0SzmIyBzb')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

    return (
     
<div name="contact" className="bg-black  px-6 mx-auto ">
 <section className=" text-gray-800 text-center">
   <div className="px-6 py-12 md:px-12">
      <div className="mx-auto xl:px-32">
        <div className="grid lg:grid-cols-2  items-center">
          <div className="md:mt-12 lg:mt-0 mb-12 lg:mb-0">
            <div
              className="bg-stone-950 block rounded-xl shadow-xl px-6 py-12 md:px-12 lg:-mr-14"
           
            >
                <div className="grid md:grid-cols-2 p-5">  <div className="mb-12 lg:mb-0 text-center mx-auto">
            <svg className="w-8 h-8 text-red-600 mb-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
            <h6 className="font-medium text-white">{data.contact3}
</h6>
          </div>
          <div className="mb-6 md:mb-0 text-center mx-auto">
            <a href="tel:+36 30 480 4374">
            <svg className="w-8 h-8 text-red-600 mb-6 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
            <h6 className="font-medium text-white">‭+36 30 480 4374‬</h6>
            </a>
          </div></div>
            <h2 className="text-3xl font-bold mb-12 text-white">{data.contact1} </h2>
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleInput7"
                  placeholder="Name"
                  name="user_name"
                />
              </div>
              <div className="form-group mb-6">
                <input
                  type="email"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleInput8"
                  name="user_email"
                  placeholder="Email address"
                />
              </div>
              <div className="form-group mb-6">
                <textarea
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="message"
                  rows="3"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="form-group form-check text-center mb-6">
                
              </div>
              <button
                value="Send"
                type="submit"
                onClick={notify}
                className="w-full px-6 py-2.5 bg-red-800 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              ><ToastContainer />
                {data.contact4}
              </button>
            </form>
            </div>
          </div>
          <div className=" md:mb-12 lg:mb-0">
            <div className=" z-1 h-[700px] relative shadow-lg rounded-lg">
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2693.8791996485584!2d19.085160811549677!3d47.531214793060585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741db978332f005%3A0x9d42c89f974c48a3!2sBudapest%2C%20Petneh%C3%A1zy%20u.%2090%2C%201135!5e0!3m2!1shu!2shu!4v1679927227974!5m2!1shu!2shu" className="left-0 top-0 h-full w-full absolute rounded-lg" ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
 

</div> 
    
    )
}

export default Contact


