import dayjs from "dayjs";


export const generateDate = (
    month = dayjs().month(),
    year = dayjs().year(),
    events = []
) => {
    const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
    const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

    const arrayOfDate = [];

    // fix date of calendar
    for (let i = 0; i < firstDateOfMonth.day(); i++) {
        const date = firstDateOfMonth.day(i);
        arrayOfDate.push({
            currentMonth: false,
            date,
        });
    }

    // create current date
    for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
        const date = firstDateOfMonth.date(i);
        const eventIndex = Math.floor(Math.random() * events.length);
        const event = events[eventIndex];
        events.splice(eventIndex, 1);
        arrayOfDate.push({
            currentMonth: true,
            date,
            today: date.toDate().toDateString() === dayjs().toDate().toDateString(),
            event,
        });
    }

    const remaining = 42 - arrayOfDate.length;

    for (let i = lastDateOfMonth.date() + 1; i <= lastDateOfMonth.date() + remaining; i++) {
        const date = lastDateOfMonth.date(i);
        arrayOfDate.push({
            currentMonth: false,
            date,
        });
    }

    return arrayOfDate;
};


    
    export const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    