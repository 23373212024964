import React from 'react';
import {  FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import Hot from '../assets/hot-girly.png';
import { NavLink as RouterLink } from "react-router-dom";

export const Footer = ({data}) => {
    
  const currentYear = new Date().getFullYear();
    
    return (
        <footer className="bg-black text-white">
          
<div className=" w-full  flex  flex-row  flex-wrap  bg-black  p-10  py-20  justify-center" >
  <div className=" w-full  text-center">
    <div className=" text-3xl  text-center text-white  antialiased">{data.footer6}</div>
    <div className="text-xl   text-center  text-white antialiased">{data.footer7}</div>
  </div>
  
  <form className=" mt-3  flex   flex-row   flex-wrap" action="" method="POST">
	      <div className="text-gray-600  w-2/3">
	        <input type="text" name="email" className="  w-full  p-2   rounded-l-lg" placeholder="e-mail@mail.com"/>
	      </div>
	     	<div className=" w-1/3">
	     	  <button className=" w-full text-white  p-2  bg-red-700  rounded-r-lg text-center hover: bg-red-300" type="submit">{data.footer5}</button>
	     	</div>
	 </form>
</div>

<div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">

      <div className="flex justify-center">
       <img  src={Hot} alt="logo"/>
      </div>
<p className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500">{data.footer1} </p>
    <div className="mt-12 flex justify-center gap-6 md:gap-8">
          <a className="text-gray-700 transition hover:text-gray-300/75" href='https://www.facebook.com/HotGirlyDanceClass'> <FaFacebook size={30} /> </a>
          <a className="text-gray-700 transition hover:text-gray-300/75" href='https://www.instagram.com/hotgirlydanceclass/'> <FaInstagram size={30} /> </a>
          <a className="text-gray-700 transition hover:text-gray-300/75" href='https://www.youtube.com/@lorettalakatos8454'> <FaYoutube size={30} /> </a>
          <a className="text-gray-700 transition hover:text-gray-300/75" href='https://www.tiktok.com/@hotgirlydanceclass'> <FaTiktok size={30} /> </a> 
        </div>

      <div className=' flex justify-center mt-6'>
    <div className="mt-12">
       
        <ul className="flex  justify-center  md:gap-6 lg:gap-10">
            <li className="text-gray-700 transition hover:text-gray-700/75">
            <RouterLink className="p-2 text-xl" to="/faq">{data.footer2} </RouterLink>
                </li>
                
                 <li className="text-gray-700 transition hover:text-gray-700/75">	
            <a className="p-2 text-xl" href="Hotgirly-24.jpg">
                 {data.nav4}
            </a></li>
           
            <li className="text-gray-700 transition hover:text-gray-700/75"><RouterLink className="p-2 text-xl" to="/privacy">{data.footer3} </RouterLink></li>

           
        </ul>
    </div>
    
   
    
      </div>
    </div>
       <span className="grid  sm:grid-cols-2 lg:grid-cols-1 gap-10
      text-center pt-2 text-gray-400 text-sm pb-8">
        © {currentYear} {data.footer4}
        </span>
    </footer>
    )
}
