import React, { useState, useEffect } from 'react';
import {FaArrowUp } from 'react-icons/fa';

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-10 right-10 p-4 bg-red-600 text-white rounded-full shadow-md transition duration-300 ease-in-out hover:bg-red-700"
        >
          <FaArrowUp/>
        </button>
      )}
    </div>
  );
};

export default ScrollButton;