import React from 'react'
import Lili from '../assets/Lili.jpeg';
import Lola from '../assets/Lola.jpeg';
import Niki from '../assets/Niki.jpeg';



const KozepHalado = () => {
  return (
    <div className="bg-black min-h-screen overflow-hidden">
      <h1 className='p-6 text-xl text-white'>Középhaladó órák</h1>
      <div className="m-6 flex flex-wrap gap-7">
        {[
          { img: Lili, name: "Sziklai Lili", type: "Középhaladó", schedule: "Kedd-Csütörtök 19:30-21:00", address: "1147 Bp, Telepes u. 51. Táncterem Zugló" },
          { img: Lola, name: "Lakatos Loretta", type: "Hot motivated", schedule: "Hétfő-Szerda 19:00-20:00", address: "1139 Bp, Petneházy u. 81. HOT STUDIO" },
          { img: Niki, name: "Lehelvári Niki", type: "Középhaladó", schedule: "Kedd-csütörtök 19:00-20:00", address: "1025 Bp, Szépvölgyi út 44. La Place Studio" }
        ].map((course, index) => (
          <div key={index} className="flex flex-col shadow-sm border border-red-400 rounded-lg w-96 m-2">
            <div className="m-2.5 overflow-hidden rounded-md h-80 flex justify-center items-center">
              <img className="w-5/6 h-full rounded-lg object-cover" src={course.img} alt="profile-picture" />
            </div>
            <div className="p-6 text-center">
              <h4 className="mb-1 text-xl font-semibold text-slate-300">{course.name}</h4>
              <p className="text-sm font-semibold text-slate-500 uppercase">{course.type}</p>
              <p className="text-base text-slate-600 mt-4 font-light">{course.schedule}</p>
              <p className="text-base text-slate-600 mt-4 font-light">{course.address}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default KozepHalado;
