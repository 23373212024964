import React, { useState } from 'react';
import axios from 'axios';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Email: ', email);
  
    if (!email) {
      setError('Kérlek add meg az e-mail címed!');
      return;
    }
  
    setLoading(true);
    setError('');
    setSuccess(false);
  
    try {
      console.log('Küldés...');
      const response = await axios.post(
        'https://api.mailerlite.com/api/v2/subscribers',
        { email: email },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-MailerLite-ApiKey': process.env.REACT_APP_MAILERLITE_API_KEY,
          },
        }
      );
      console.log('API válasz:', response);
  
      if (response.status === 200) {
        setSuccess(true);
        setEmail('');
      }
    } catch (err) {
      console.error('API hiba:', err);
      setError('Hiba történt a feliratkozás során. Kérlek próbáld újra!');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <h2>Feliratkozás hírlevélre</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Add meg az e-mail címed"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Küldés...' : 'Feliratkozás'}
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>Sikeresen feliratkoztál!</p>}
    </div>
  );
};

export default NewsletterSignup;
