import React, { useState } from "react";
import Back from "../assets/IMG_0504.jpeg";

export default function Questions({data}) {
    const [box1, setBox1] = useState(false);
    const [box2, setBox2] = useState(false);
    const [box3, setBox3] = useState(false);
    const [box4, setBox4] = useState(false);
    const [box5, setBox5] = useState(false);
    const [box6, setBox6] = useState(false);
    const [box7, setBox7] = useState(false);
    const [box8, setBox8] = useState(false);

    return (        
        
            <div name="questions" className="w-screen h-screen">
                <img src={Back} alt="background" className="absolute  bg-contain bg-center  z-0" />
                <div className="relative flex flex-col items-center justify-center ">
                    <div className="md:py-8 py-8">
                        <h1 className="xl:text-6xl md:text-5xl sm:text-xl font-bold leading-10 text-black">
                           {data.question} </h1>
                    </div>
                    <div className="lg:w-1/2 md:w-8/12 sm:w-9/12 w-full">
                        <div className="bg-white shadow rounded p-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question1} </h2>
                                </div>
                                <button onClick={() => setBox1(!box1)} className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer">
                                    {box1 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>

                            {box1 && (
                                <ul className="">
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">16+</p>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question2} </h2>
                                </div>
                                <button
                                    onClick={() => {
                                        setBox2(!box2);
                                    }}
                                    data-menu
                                    className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
                                >
                                    {box2 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {box2 && (
                                <ul>
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">{data.answer2} </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question3} </h2>
                                </div>
                                <button
                                    onClick={() => {
                                        setBox3(!box3);
                                    }}
                                    data-menu
                                    className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
                                >
                                    {box3 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {box3 && (
                                <ul>
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">{data.answer3} </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question4} </h2>
                                </div>
                                <button onClick={() => setBox4(!box4)} data-menu className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer">
                                    {box4 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {box4 && (
                                <ul>
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">{data.answer4} </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="lg:w-1/2 md:w-8/12 sm:w-9/12 w-full">
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question5} </h2>
                                </div>
                                <button onClick={() => setBox5(!box5)} className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer">
                                    {box5 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>

                            {box5 && (
                                <ul className="">
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">{data.answer5} </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question6} </h2>
                                </div>
                                <button
                                    onClick={() => {
                                        setBox6(!box6);
                                    }}
                                    data-menu
                                    className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
                                >
                                    {box6 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {box6 && (
                                <ul>
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">{data.answer6} </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question7} </h2>
                                </div>
                                <button
                                    onClick={() => {
                                        setBox7(!box7);
                                    }}
                                    data-menu
                                    className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer"
                                >
                                    {box7 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {box7 && (
                                <ul>
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">  {data.answer7}
                                        </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="bg-white shadow rounded p-8 mt-8">
                            <div className="flex items-center justify-between">
                                <div>
                                    <h2 className="text-base font-semibold leading-none text-gray-800">{data.question8} </h2>
                                </div>
                                <button onClick={() => setBox8(!box8)} data-menu className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ring-offset-white cursor-pointer">
                                    {box8 ? (
                                        <svg role="button" aria-label="close dropdown" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 5L5 1L9 5" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg width="10" role="button" aria-label="open dropdown" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5 5L9 1" stroke="#4B5563" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    )}
                                </button>
                            </div>
                            {box8 && (
                                <ul>
                                    <li>
                                        <p className="text-base leading-normal text-gray-600 mt-4 lg:w-96">{data.answer8} <br></br> {data.answer8a}<br></br>  {data.answer8b} <br></br> {data.answer8c} </p>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
    );
}
