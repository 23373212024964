import React from 'react'
import Netti from '../assets/Netti.jpeg';
import Vilma from '../assets/Vilma.jpeg';



const Openclass = () => {
  return (
    <div className="bg-black min-h-screen overflow-hidden">
      <h1 className='p-6 text-xl text-white'>Open órák</h1>
      <div className="m-6 flex flex-wrap gap-7">
        {[
          
          
          { img: Vilma, name: "Gera Vilma", type: "open", schedule: "Kedd 20:00-21:30", address: "1139 Bp, Petneházy u. 81. HOT STUDIO"  },
          { img: Netti, name: "Erős Anett", type: "open", schedule: "péntek 19:30-21:00", address: "1139 Bp, Petneházy u. 81. HOT STUDIO"  },
        ].map((course, index) => (
          <div key={index} className="flex flex-col shadow-sm border border-red-400 rounded-lg w-96 m-2">
            <div className="m-2.5 overflow-hidden rounded-md h-80 flex justify-center items-center">
              <img className="w-5/6 h-full rounded-lg object-cover" src={course.img} alt="profile-picture" />
            </div>
            <div className="p-6 text-center">
              <h4 className="mb-1 text-xl font-semibold text-slate-300">{course.name}</h4>
              <p className="text-sm font-semibold text-slate-500 uppercase">{course.type}</p>
              <p className="text-base text-slate-600 mt-4 font-light">{course.schedule}</p>
              <p className="text-base text-slate-600 mt-4 font-light">{course.address}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Openclass;
