import video from '../assets/hot_logo_intro.mp4'

const Header = () => {
    return (
        <div name="header" className='bg-black w-full h-full bg-center flex items-center '>
            
                <video src={video} autoPlay loop muted/>
             
            
        </div>
    )
}

export default Header
