import React, {useState} from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Hot from "../assets/hot-girly.png";
import { NavLink as RouterLink,useLocation, useNavigate, Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import * as Scroll from "react-scroll";
import Language from '../components/LanguageBtn';


export default function NavBar ({data, i18n}) {
  
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const path = useLocation().pathname;
    const location = path.split("/")[1];
    const navigate = useNavigate();
    const scroller = Scroll.scroller;
  
    const goToPageAndScroll = async (selector) => {
      await navigate("/");
      await scroller.scrollTo(selector, {
        duration: 500,
        smooth: true,
        offset: -75,
        spy: true
      });
    };
    return (
        <div name="nav" className='bg-black flex justify-between items-center h-20  mx-auto px-3 text-white'>
        <div className="flex ">
           <Link  to="header" className="m-2">
               <img src={Hot} alt="logo" style={{ width: '70px' }} />
           </Link>
           <div className="m-7"></div>
             <Language  i18n={i18n}/>
           </div>
          
    <nav>
    
      <ul className='hidden md:flex'>
      {location !== "events" && location !== "ref" && location !== "faq" ? (
      <>
      <li>
        
        {" "}
        <ScrollLink className="p-2" to="about" spy={true} smooth={true} offset={-75} duration={500}>
          {data.nav1}
        </ScrollLink>
      </li>
      <li>
        <ScrollLink className="p-2" to="teachers" spy={true} smooth={true} offset={-75} duration={500}>
          {data.nav2}
        </ScrollLink>
      </li>
      <li>
        <ScrollLink className="p-2" to="calendar" spy={true} smooth={true} offset={-75} duration={500}>
          {data.nav4}
        </ScrollLink>
      </li>
      <li>
        <ScrollLink className="p-2" to="trainings" spy={true} smooth={true} offset={-75} duration={500}>
          {data.nav3}
        </ScrollLink>
      </li>
        <li>
        <RouterLink className="p-2" to="/events">{data.nav5} </RouterLink>
      </li>
      {/* <li>
        <RouterLink className="p-2" to="/ref">{data.nav6} </RouterLink>
      </li> */}
      <li>
        <ScrollLink className="p-2" to="contact" spy={true} smooth={true} offset={-75} duration={500}>
          {data.nav7}
        </ScrollLink>
      </li>
    
    </>
  ) :(
        <>
          <li>
            {" "}
            <button onClick={() => goToPageAndScroll("about")}>{data.nav1} </button>
          </li>
          <li>
            <button onClick={() => goToPageAndScroll("teachers")}>{data.nav2} </button>
          </li>
          <li>
            <button onClick={() => goToPageAndScroll("calendar")}>{data.nav4} </button>
          </li>
          <li>
            <button onClick={() => goToPageAndScroll("trainings")}> {data.nav3}</button>
          </li>
          
          <li>
            <RouterLink to="/events">{data.nav5} </RouterLink>
          </li>
          <li>
            <RouterLink to="/ref">{data.nav6} </RouterLink>
          </li>
          <li>
            <button onClick={() => goToPageAndScroll("contact")}>{data.nav7} </button>
          </li>
        </>
      )}
      </ul>
      
      <div onClick={handleClick} className=' md:hidden z-10'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul
  className={
    !nav
      ? 'hidden'
      : 'absolute top-0 left-0 w-full h-screen bg-black flex flex-col justify-center items-center z-50 '
  }
>
  <li className="p-4 m-2 text-center">
    <ScrollLink
      onClick={handleClick}
      to="about"
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    ><Link onClick={handleClick} to="about"> {data.nav1}</Link>
     
    </ScrollLink>{' '}
  </li>
  <li className="p-4 m-2 text-center">
    <ScrollLink
      onClick={handleClick}
      to="teachers"
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    >
     <Link onClick={handleClick} to="teachers">{data.nav2} </Link> 
    </ScrollLink>
  </li>
  <li className="p-4 m-2 text-center">
    <ScrollLink
      onClick={handleClick}
      to="calendar"
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    >
       <Link onClick={handleClick} to="calendar">{data.nav4} </Link> 
    </ScrollLink>
  </li>
  <li className="p-4 m-2 text-center">
    <ScrollLink
      onClick={handleClick}
      to="trainings"
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    >
    <Link onClick={handleClick} to="trainings">{data.nav3} </Link> 
    </ScrollLink>
  </li>
  <li className="p-4 m-2 text-center">
    <RouterLink onClick={handleClick} to="/events">
      {data.nav5}
    </RouterLink>{' '}
  </li>
 {/*  <li className="p-4 m-2 text-center">
    <RouterLink onClick={handleClick} to="/ref">
      {data.nav6}
    </RouterLink>{' '}
  </li> */}
  <li className="p-4 m-2 text-center">
    <ScrollLink
      onClick={handleClick}
      to="contact"
      smooth={true}
      duration={500}
      spy={true}
      exact="true"
      offset={-80}
    >
      <Link onClick={handleClick} to="contact">{data.nav7} </Link>
    </ScrollLink>{' '}
  </li>
</ul>
      </nav>
    </div>
    )
}
