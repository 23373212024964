import React from 'react'

const Cookies = ({data}) => {
    return (
        <div className=" p-10 bg-black text-white">
         <h1 className=" text-xl text-bold mt-4 text-center m-12 "> {data.privacy1}</h1>   
<h3>{data.privacy2}</h3>
<p>{data.privacy3}</p>
<h3>{data.privacy4}</h3>
<p>{data.privacy5}</p>
<h3>{data.privacy6}</h3>
<p>{data.privacy7}</p>
<h3>{data.privacy8}</h3>
<p>{data.privacy9}</p>
        </div>
    )
}

export default Cookies
