import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./pages/NavBar";
import Content from "./components/Content";
import Questions from "./pages/Questions";
import Events from "./routes/events";
import Privacy from "./routes/faq";
import Kezdo from "./routes/Kezdo";
import data from './data'; 
import { useTranslation } from 'react-i18next';
import NewsletterPopup from "./pages/NewsletterPopup";
import Kozeph from './routes/Kozeph';
import Halado from './routes/halado';
import Open from "./routes/nyilt";

export default function App() {
  const { i18n } = useTranslation();
  const [allData, setAllData] = useState([]);


  useEffect(() => {
    i18n.changeLanguage('hu');
    setAllData(i18n.language === 'hu' ? data.hu : data.en);
  }, [i18n]);
  

  return (
    <>
      <Router>
        <Navigation data={allData} i18n={i18n} />
        <Routes>
          <Route path="*" element={<Content />} />
          <Route path="/events" element={<Events />} />
          <Route path='/kezdo' element={<Kezdo />} />
          <Route path='/kozep' element={<Kozeph />} />
          <Route path='/halado' element={<Halado />} />
          <Route path='/open' element={<Open/>} />
          <Route path="/faq" element={<Questions data={allData} i18n={i18n}/>} />
          <Route path="/privacy" element={<Privacy data={allData} i18n={i18n}/>} />
        </Routes>
        <NewsletterPopup data={allData} i18n={i18n} />
      </Router>
    </>
  );
}
