import React from 'react';
import Picture from '../assets/cover.jpg';

export default function About({data} ) {
    
    return (
        <section className="bg-black py-12 lg:py-32 bg-grey-800">
            
                <div name="about">  
                    <img className="object-cover h-full w-full  md:my-auto lg:mx-0  " src={Picture} alt="us"/>
                    <div className="flex flex-col  text-center lg:items-start lg:text-center">
                        <div id='about-section' className="flex flex-col">
                            <h2 className="text-3xl py-8 lg:text-4xl font-medium lg:font-extrabold mb-3 lg:pt-36
                            before:content-about relative
                            before:absolute before::opacity-40
                            before:-top-[2rem] before:hidden
                            before:lg:block ">
                             {data.about1}
                            </h2>
                            <p className="mb-4 text-red-500 text-3xl">
                            Hot Girly Dance Class
                            </p>
                            <hr className="mb-8 opacity-5"/>
                            <p className="mb-8 text-2xl text-justify m-6 p-6 text-white">
                          {data.about2}
                            </p>
                            
                        </div>
                        
                    </div>
                </div>
           
         
        </section>
    );
}

