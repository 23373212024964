import React, { useState } from "react";
import dayjs from "dayjs";
import { generateDate, months } from "../Schedule/calendar";
import cn from "../Schedule/cn";
import { GrFormNext, GrFormPrevious} from "react-icons/gr";
import moment from 'moment';
import Lola from "../../assets/Lola.jpeg";
import Lili from "../../assets/Lili.jpeg";
import Netti from "../../assets/Netti.jpeg";
import Niki from "../../assets/Niki.jpeg";
import Vilma from "../../assets/Vilma.jpeg";
import Flóra from "../../assets/Flóra.jpeg";
import Lilla from "../../assets/Lilla.jpeg";
import {FaClock } from "react-icons/fa";
import {MdLocationOn} from "react-icons/md";
import {MdOutlineDownloading} from "react-icons/md";



export default function Calendar() {
	const days = ["S", "M", "T", "W", "T", "F", "S", ];
	const currentDate = dayjs();
	const [today, setToday] = useState(currentDate);
	const [selectDate, setSelectDate] = useState(currentDate);
	const events = [
		{
			title: 'Lili',
			start: moment().day("Monday").hour(18).minute(0).toDate(),
			end: moment().day("Monday").hour(19).minute(0).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT START UP kurzus',
			pic: Lili,
		  },
		{
			title: 'Lola',
			start: moment().day("Monday").hour(19).minute(0).toDate(),
			end: moment().day("Monday").hour(20).minute(0).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT MOTIVATED BASIC képzés',
			pic: Lola,
		  },
		  {
			title: 'Lola',
			start: moment().day("Monday").hour(20).minute(0).toDate(),
			end: moment().day("Monday").hour(21).minute(30).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT MOTIVATED STAGE képzés',
			pic: Lola,
		  },
		  {
			title: 'Lola' ,
			start: moment().day("Tuesday").hour(19).minute(0).toDate(),
			end: moment().day("Tuesday").hour(20).minute(0).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT START UP',
			pic: Lola,
		  },
		  {
			title: 'Lola' ,
			start: moment().day("Thursday").hour(19).minute(0).toDate(),
			end: moment().day("Thursday").hour(20).minute(0).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT START UP',
			pic: Lola,
		  },
		  {
			title: 'Lili',
			start: moment().day("Wednesday").hour(18).minute(0).toDate(),
			end: moment().day("Wednesday").hour(19).minute(0).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT START UP',
			pic: Lili,
		  },
		  {
			title: 'Lola',
			start: moment().day("Wednesday").hour(19).minute(0).toDate(),
			end: moment().day("Wednesday").hour(20).minute(0).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT MOTIVATED BASIC képzés',
			pic: Lola,
		  },
		  {
			title: 'Lola',
			start: moment().day("Wednesday").hour(20).minute(0).toDate(),
			end: moment().day("Wednesday").hour(21).minute(30).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			name: 'HOT MOTIVATED STAGE képzés',
			pic: Lola,
		  },
		
		{
			title: 'Lili',
			start: moment().day("Tue").hour(19).minute(30).toDate(),
			end: moment().day("Tue").hour(21).minute(0).toDate(),
			place: 'Telepes utca 51 - Táncterem Zugló',
			pic: Lili,
		  },
		{
			title:'Lili',
			start: moment().day("Thursday").hour(19).minute(30).toDate(),
			end: moment().day("Thursday").hour(20).minute(30).toDate(),
			place: 'Telepes utca 51 - Táncterem Zugló',
			pic: Lili,
		},
		{
			title: 'Vilma',
			start: moment().day("Tuesday").hour(20).minute(0).toDate(),
			end: moment().day("Tuesday").hour(21).minute(30).toDate(),
			place: 'Petneházy u. 81. 1. emelet',
			pic: Vilma,
		  },
		  {
			title: 'Niki',
			start: moment().day("Tuesday").hour(19).minute(0).toDate(),
			end: moment().day("Tuesday").hour(20).minute(0).toDate(),
			place: 'Szépvölgyi út 44. La Place Studio',
			pic: Niki,
		  },
		  {
			title: 'Niki',
			start: moment().day("Thursday").hour(19).minute(0).toDate(),
			end: moment().day("Thursday").hour(20).minute(0).toDate(),
			place: 'Szépvölgyi út 44. La Place Studio',
			pic: Niki,
		  },
		
		{
			title:'Netti',
			start: moment().day("Thursday").hour(19).minute(30).toDate(),
			end: moment().day("Thursday").hour(21).minute(0).toDate(),
			place: 'Ipar utca 3. Performance Studio',
			pic: Netti,
		},
		{
			title:'Netti',
			start: moment().day("Friday").hour(19).minute(0).toDate(),
			end: moment().day("Friday").hour(20).minute(30).toDate(),
			place: 'Petneházy u. 81. I. emelet',
			pic: Netti,
		},
		{
			title:'Flóra',
			start: moment().day("Tue").hour(20).minute(0).toDate(),
			end: moment().day("Tue").hour(21).minute(0).toDate(),
			place: 'Dohány utca 5/b Astoria',
			pic: Flóra,
		},
		{
			title:'Flóra',
			start: moment().day("Thursday").hour(20).minute(15).toDate(),
			end: moment().day("Thursday").hour(21).minute(30).toDate(),
			place: '8000 Székesfehérvár, Széchényi út 122.',
			pic: Flóra,
		},
		{
			title: 'Lilla',
			start: moment().day("Friday").hour(18).minute(30).toDate(),
			end: moment().day("Friday").hour(20).minute(0).toDate(),
			place: 'KÖKI TERMÁL F5 Fitness, Bp, Vak Bottyán u. 75/A/C',
			pic: Lilla,
		  },
	];
	
	
	  const monthEvents = events.filter(
		(event) => moment(event.start).day() === selectDate.day()
	);
	
	  
	return (
		
		<div name="calendar" id="calendar-section" className="bg-black flex gap-10 sm:divide-x justify-center  mx-auto  h-screen items-center sm:flex-row flex-col">
			<div className='relative hidden lg:flex  flex-col '>
                <ul>
                    <li className='w-30 h-20 flex rounded-3xl justify-between items-center hover:w-5/6   bg-red-600'>
					<a
                            className='flex justify-between items-center w-full text-gray-300'
                            href="Hotgirly-24.jpg"
                            
                        >
                            
                           <MdOutlineDownloading size={30} />
                        </a>
                    </li>
                    
                
                </ul>
            </div>
			<div className="w-96 h-96 ">
				<div className="text-white flex justify-between items-center">
					<h1 className="select-none font-semibold">
						{months[today.month()]}, {today.year()}
					</h1>
					<div className="flex gap-10 items-center ">
						<GrFormPrevious
							className="bg-gray-500 w-5 h-5 cursor-pointer hover:scale-105 transition-all"
							onClick={() => {
								setToday(today.month(today.month() - 1));
							}}
						/>
						<h1
							className="text-white cursor-pointer hover:scale-105 transition-all"
							onClick={() => {
								setToday(currentDate);
							}}
						>
							
						</h1>
						<GrFormNext
							className="bg-gray-500 w-5 h-5 cursor-pointer hover:scale-105 transition-all"
							onClick={() => {
								setToday(today.month(today.month() + 1));
							}}
						/>
					</div>
				</div>
				<div className="grid grid-cols-7 ">
					{days.map((day, index) => {
						return (
							<h1
								key={index}
								className="text-white text-sm text-center h-14 w-14 grid place-content-center  select-none"
							>
								{day}
							</h1>
						);
					})}
				</div>

				<div className="ml-2 grid grid-cols-7 ">
					{generateDate(today.month(), today.year()).map(
						({ date, currentMonth, today }, index) => {
							return (
								<div
									key={index}
									className="text-white p-2 text-center h-14 grid place-content-center text-sm border-t"
								>
									<h1
										className={cn(
											currentMonth ? "" : "text-gray-500",
											today
												? "bg-red-600 text-white"
												: "",
											selectDate
												.toDate()
												.toDateString() ===
												date.toDate().toDateString()
												? "bg-black text-white"
												: "",
											"h-10 w-10 rounded-full grid place-content-center hover:bg-black hover:text-white transition-all cursor-pointer select-none"
										)}
										onClick={() => {
											setSelectDate(date);
										}}
									>
										{date.date()}
									</h1>
								</div>
							);
						}
					)}
				</div>
			</div>
			<div className="overflow-y-auto ml-3 h-96 w-96 sm:px-5">
  <h1 className="text-white font-semibold">
    {selectDate.toDate().toDateString()}
  </h1>
  <div className="grid gap-2">
    {monthEvents.length > 0 ? (
      monthEvents.map((event, index) => (
        <div key={events[index].start}>
          <div className="flex">
            <img
              className="h-10 w-10 rounded-full mb-2 "
              alt="pic"
              src={event.pic}
            />
            <p className="text-white font-bold relative m-2 text-lg">
              {event.title}
            </p>
          </div>
          <div className="text-white text-base  ml-3 ">
            <FaClock className="float-left w-[12px] h-[12px] mt-1 mr-2" />
            <p>
			{moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}
            </p>
            <p className="text-white text-base ">{event.name}</p>
          </div>

          <div className="text-base  ml-2 ">
            <MdLocationOn className="text-white float-left w-[17px] h-[17px] mt-0.5  mr-2" />
            <p className="text-white text-base">{event.place}</p>
          </div>
          <div className="h-0.5 bg-gradient-to-r from-red-500 to-black"></div>
        </div>
      ))
    ) : (
      <p></p>
    )}
  </div>
</div>
		</div>
	);
}