import React from 'react';
import Tabor from '../assets/tabor.jpg';
import halloween from '../assets/halloween.jpg';
import { FaFacebook } from 'react-icons/fa';

export default function Events({data}) {
 
    return (
        <div name='events' className="bg-black">
            <div className="max-w-screen-xl mx-auto px-8 py-16 text-center md:text-left">
                <h2 className="text-white text-5xl text-center tracking-wider uppercase text-grey-500 font-bold">
                {data.events1}
                </h2>
                <p className="text-white py-12 text-center px-8 ">
                {data.events2}
                </p>
<div className=" grid md:grid-cols-1 gap-12">

<div className="flex justify-center">
  <div
    className="flex-row rounded-lg bg-white shadow-lg dark:bg-neutral-700 md:max-w-xl md:flex-row">
    <img
      className=" w-96 rounded-lg object-top md:h-auto  md:rounded-none md:rounded-l-lg sm:h-full sm:w-full "
      src={Tabor}
      alt="" />
    <div className="flex flex-col justify-start p-2">
      <h5
        className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
       {data.events3}
      </h5>
      <a href="https://www.facebook.com/events/548312874379888">
        <FaFacebook size={30} />
      </a>
      
    </div>
  </div>
</div> 

<div className="flex justify-center">
  <div
    className="flex-row rounded-lg bg-white shadow-lg dark:bg-neutral-700 md:max-w-xl md:flex-row">
    <img
      className=" w-full rounded-lg object-top md:h-auto  md:rounded-none md:rounded-l-lg"
      src={halloween}
      alt="" />
    <div className="flex flex-col justify-start p-2">
      <h5
        className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50">
        {data.events5}
      </h5>
      <a href="https://www.facebook.com/events/542493941661036/?acontext=%7B%22event_action_history%22%3A[]%7D">
        <FaFacebook size={30} />
      </a>
      
    </div>
  </div>
</div>
    </div>
        </div>
          </div>
    );
}

