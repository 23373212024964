import React from "react";
import { NavLink as RouterLink } from "react-router-dom";

export default function Trainings({ data }) {
  return (
    <div
      name="trainings"
      className="bg-black w-full h-full flex p-5 relative min-h-screen items-center justify-center"
    >
      <p className="text-3xl absolute inline top-10 object-center m-7 gap-20 text-gray-300 hover:text-gray-500 lg:grid-cols-1">
        <RouterLink to="/faq"> {data.nav3} </RouterLink>
        <h4 className="text-sm text-gray-300 top-4">A képzések linkre kattintva részeletes információt találtok </h4>
      </p>
      
      <div className="m-5 center grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
        
        {/* First Box */}
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative w-full group max-w-md min-w-0 mx-auto mt-6 mb-6 break-words bg-white border shadow-2xl dark:bg-black dark:border-gray-700 md:max-w-sm rounded-xl">
            <div className="pb-6">
              <div className="mt-20 text-center">
                <h3 className="mb-1 text-2xl font-bold leading-normal text-gray-700 dark:text-gray-300">
                  {data.trainings}
                </h3>
              </div>
              <div className="pt-6 mx-6 mt-6 text-center border-t border-gray-200 dark:border-gray-700/50">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-6">
                    <p className="mb-4 font-light leading-relaxed text-gray-600 dark:text-gray-400">
                      {data.basic}
                    </p>
                  </div>
                  <RouterLink
                    className="text-red-800 font-semibold text-sm hover:underline hover:text-red-500 flex items-center"
                    to="/kezdo"
                  >
                    {data.nav2}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Box */}
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative w-full group max-w-md min-w-0 mx-auto mt-6 mb-6 break-words bg-white border shadow-2xl dark:bg-black dark:border-gray-700 md:max-w-sm rounded-xl">
            <div className="pb-18">
              <div className="mt-20 text-center">
                <h3 className="mb-1 text-2xl font-bold leading-normal text-gray-700 dark:text-gray-300">
                  {data.trainings2}
                </h3>
                <div className="flex flex-row justify-center w-full mx-auto space-x-2 text-center"></div>
              </div>
              <div className="pt-6 mx-6 mt-6 text-center border-t border-gray-200 dark:border-gray-700/50">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-6">
                    <p className="mb-4 font-light leading-relaxed text-gray-600 dark:text-gray-400">
                      {data.startup}
                      <br /> {data.startup1}
                      <br /> {data.startup2}
                      <br /> {data.startup3}
                      <br /> {data.startup4}
                    </p>
                  </div>
                  <RouterLink
                    className="text-red-800 font-semibold text-sm hover:underline hover:text-red-500 flex items-center"
                    to="/kozep"
                  >
                    {data.nav2}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Third Box */}
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative w-full group max-w-md min-w-0 mx-auto mt-6 mb-6 break-words bg-white border shadow-2xl dark:bg-black dark:border-gray-700 md:max-w-sm rounded-xl">
            <div className="pb-6">
              <div className="mt-20 text-center">
                <h3 className="mb-1 text-2xl font-bold leading-normal text-gray-700 dark:text-gray-300">
                  {data.trainings3}
                </h3>
              </div>
              <div className="pt-6 mx-6 mt-6 text-center border-t border-gray-200 dark:border-gray-700/50">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-6">
                    <p className="mb-4 font-light leading-relaxed text-gray-600 dark:text-gray-400">
                      {data.motivated}
                    </p>
                  </div>
                  <RouterLink
                    className="text-red-800 font-semibold text-sm hover:underline hover:text-red-500 flex items-center"
                    to="/halado"
                  >
                    {data.nav2}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Fourth Box - New Box */}
        <div className="flex items-center justify-center min-h-screen">
          <div className="relative w-full group max-w-md min-w-0 mx-auto mt-6 mb-6 break-words bg-white border shadow-2xl dark:bg-black dark:border-gray-700 md:max-w-sm rounded-xl">
            <div className="pb-6">
              <div className="mt-20 text-center">
                <h3 className="mb-1 text-2xl font-bold leading-normal text-gray-700 dark:text-gray-300">
                  OPEN
                </h3>
              </div>
              <div className="pt-6 mx-6 mt-6 text-center border-t border-gray-200 dark:border-gray-700/50">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-6">
                    <p className="mb-4 font-light leading-relaxed text-gray-600 dark:text-gray-400">
                      {data.openclass} 
                    </p>
                  </div>
                  <RouterLink
                    className="text-red-800 font-semibold text-sm hover:underline hover:text-red-500 flex items-center"
                    to="/open"
                  >
                    {data.nav2}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </RouterLink>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
