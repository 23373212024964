import React, {useState,useEffect} from 'react';
import Lola from "../assets/Lola.jpeg";
import Lili from '../assets/Lili.jpeg';
import Netti from '../assets/Netti.jpeg';
import Niki from '../assets/Niki.jpeg';
import Vilma from '../assets/Vilma.jpeg';
import Flóra from '../assets/Flóra.jpeg';
import Lilla from '../assets/Lilla.jpeg';
import {FaFacebook, FaInstagram} from "react-icons/fa";



const Teachers = ({data}) => {
  
  const teachers = [
    {name:'Lola', 
     image: Lola,
     color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
     bio:data.teachers1,
     insta: 'https://instagram.com/lakatosloretta_lola?igshid=YmMyMTA2M2Y=',
     fb: 'https://www.facebook.com/loretta.lakatos.3',
  },

    {name:'Lili', 
     image: Lili,
     color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
     bio: data.teachers2,
     insta: 'https://instagram.com/lilisziklai?igshid=YmMyMTA2M2Y=',
     fb: 'https://www.facebook.com/lili.sziklai',
  },

    {name:'Vilma', 
     image: Vilma,
     color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
     bio:data.teachers3,
     insta:'https://instagram.com/vilmagera?igshid=YmMyMTA2M2Y=',
     fb: 'https://www.facebook.com/vilma.l.gera',
  },
     
     {name:'Niki', 
      image: Niki,
      color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
      bio:data.teachers4,
      insta: 'https://instagram.com/lehelvarinikii?igshid=YmMyMTA2M2Y=',
      fb: 'https://www.facebook.com/nikolett.lehelvari',
  },

      {name:'Netti', 
      image: Netti,
      color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
      bio:data.teachers5,
      insta:'https://instagram.com/netti_eross?igshid=YmMyMTA2M2Y=',
      fb: 'https://www.facebook.com/netti.eross',
    },

    {name:'Flóra', 
    image: Flóra,
   color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
    bio:data.teachers6,
    insta:'https://www.instagram.com/_tothflora_/?igsh=ZnM2a3c0N2xxYXA1',
    fb: 'https://www.facebook.com/flora.toth.16',
  },
  
  {name:'Lilla', 
    image: Lilla,
   color: "linear-gradient(47deg, rgba(36,0,0,1) 0%, rgba(121,9,9,1) 7%, rgba(145,7,18,1) 20%, rgba(255,0,61,1) 39%)",
    bio:data.teachers7,
    insta:'https://www.instagram.com/lillaapatakii/?igsh=MXV4eWFrOXZjMjVqcg%3D%3D',
    fb: 'https://www.facebook.com/lilla.pataki.54',
  },
  ]

  const [popup, setPopup] = useState(null);
  const [popuptoggle, setpopuptoggle] = useState(false);
 

  useEffect(() => {
    if (popuptoggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [popuptoggle]);

 
  

  return (
    <div
      id="teachers"
      name="teachers"
        className="w-full h-full pt-12 pb-6 flex relative min-h-screen items-center justify-center bg-black"
    >
      <p className="text-4xl absolute  text-gray-300 lg:grid-cols-1">
        {data.nav2}
      </p>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
        {teachers.map((teacher) => (
          <div
            key={teacher.name}
            className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30"
          >
            <div className="h-96 w-72">
              <img
                className="h-full w-full  transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125"
                src={teacher.image}
                alt=""
              />
            </div>
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/60 group-hover:via-black/60 group-hover:to-black/60"></div>
            <div className="absolute inset-0 flex translate-y-[70%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
              <h1 className="font-dmserif text-3xl font-bold text-white py-4">
                {teacher.name}
              </h1>
              <button
                className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60"
                onClick={() => {
                  setPopup(teacher);
                  setpopuptoggle(true);
                }}
              >
                {data.teachers}
              </button>
              <div className="flex  ">
    <a href={teacher.insta} className="p-2" >
      <FaInstagram size={30} />
    </a>
    <a href={teacher.fb} className="p-2" >
      <FaFacebook size={30} />
    </a>
  </div>
            </div>
          </div>
        ))}
        {popup !== null && (
          <div
            className="box-border fixed top-0 bottom-0 left-0 right-0 pt-[15%] "
            onClick={() => {
              setPopup(null);
              setpopuptoggle(false);
             
            }}
          >
           <div className="w-[70%] h-[70%] bg-white max-h-[90vh] m-auto overflow-auto opacity-90" style={{backgroundImage: popup.color}} >

              <div className="w-full">
                <button onClick={() => setPopup(null)} className="p-4 ">
                  x
                </button>
              </div>
              <div className="block" >
                <p className="mx-2 text-xl pr-11 pl-11 ">{popup.bio}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    )
}

export default Teachers
